@import url('https://fonts.googleapis.com/css2?family=Nunito:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;0,1000;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900;1,1000&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

body{
  /* font-family: 'Nunito', sans-serif; */
  font-family: 'Poppins', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.bg-sky-1000{
  background: #85dff8 !important;
}

.bg-blue-1000{
  background: #2A8BF2 !important;
}

.bg-slate-1000{
  background: linear-gradient(180deg, #F3F3FB 0%, #FDFBFD 100%);
}

.bubble-list{
  background: linear-gradient(92.68deg, #85dff8 0%, #00b6dd 100%);
  /* background: linear-gradient(92.68deg, #7CB8F7 0%, #2A8BF2 100%); */
  color: white !important;
  border: none;
  margin: 10px 0px;
}

.shadow-bubble{
  box-shadow: 0px 4px 10px 0px #00000040;
}

.shadow-main{
  box-shadow: 0px 4px 10px 0px #00000040;
}

.shadow-custom{
  box-shadow: 0px 4px 10px 0px #ffffff40;
}

.section-left{
  /* background: #85dff8; */
  background: linear-gradient(92.68deg, #85dff8 0%, #00b6dd 100%);
  background-image: url('./assets/images/pattern_node_blue.png');
  background-size: cover;
  background-position-x: left;
  min-height: 100vh;
}
.section-right{
  background: #ffffff;
  background-image: url('./assets/images/pattern_node.jpg');
  background-size: cover;
  background-position-x: right;
  min-height: 100vh;
}

.section-node{
  background: #ffffff;
  background-image: url('./assets/images/pattern_node.jpg');
  background-size: cover;
  background-position-x: right;
  min-height: 100vh;
}

.bg-options{
  background: #ffffff;
  background-image: url('./assets/images/pattern_node.jpg');
  background-size: cover;
  background-position-x: right;
  height: 100vh;
}

.button-primary{
  background: linear-gradient(325.78deg, #00b6dd 14.76%, #85dff8 87.3%);
  /* background: linear-gradient(325.78deg, #2A8BF2 14.76%, #85dff8 87.3%); */
  color: #ffffff !important;
}

.bg-primary{
  background: linear-gradient(325.78deg, #00b6dd 14.76%, #85dff8 87.3%);
}

.bg-secondary{
  background: linear-gradient(to top, #0ba360 0%, #3cba92 100%);
}

.container-camera {
  position: absolute;
  left: 40%;
  bottom: 50%;
  transform: translate(-50%, -50%);
  width: 25%; /* Need a specific value to work */
}

.container-camera-record{
  position: absolute;
  transform: translate(-50%, -50%);
  width: 25%; /* Need a specific value to work */
  z-index: 100;
  left: 40%;
  bottom: 50%;
}

.w-81{
  width: 21.5rem;
}

.text-primary{
  color: #004158 !important;
}

.text-title {
  font-size: 32px;
  color: #fff;
  font-weight: 700;
  margin-top: 20px;
}
.text-desc {
  font-size: 18px;
  color: #fff;
  opacity: 0.7;
  margin-top: 8px;
}

.icon-primary {
  fill: #85dff8 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.file-upload-label>input:first-of-type{
  display: none !important;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@media only screen and (max-width: 1440px) {
  .button-logout {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .options-sidebar {
    display: none;
  }
  .button-logout {
    /* display: inline-flex;
    position: absolute;
    bottom: 75px;
    left: 10px;
    z-index: 10; */
    display: none;
  }
  .container-camera{
    width: 50%;
    bottom: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
  }

  /* .input-chat{
    width: 70%;
  } */
}

/* @media only screen and (max-width: 768px) {
  .input-chat{
    width: 80%;
  }
}
@media only screen and (max-width: 500px) {
  .input-chat{
    width: 80%;
  }
} */

@media only screen and (max-width: 425px) {
  /* .input-chat{
    width: 80%;
  } */
  .input-upload{
    width: 2rem;
    height: 2rem;
  }
  .icon-upload{
    width: 1.9rem;
    height: 1.9rem;
  }
}

@media only screen and (max-height: 640px) {
  .options-sidebar {
    display: none;
  }
}
